export enum ResourcesEndpointEnum {
  'GET_ROLE' = '/me',

  'PUT_PORTAL_CONFIGURATION'= '/configuration/portalConfiguration',

  'GET_GROUPS' = '/groups',
  'GET_GROUPSREPORTCONFIG' = '/reportConfig/groups',
  'GET_GROUP' = '/groups/:groupID',
  'POST_GROUP' = '/groups',
  'PUT_GROUP' = '/groups',
  'DEACTIVATE_GROUP' = '/groups/:groupID',
  'ACTIVATE_GROUP' = '/groups',
  'UPLOAD_GROUP_LOGO' = '/groups/:groupID/logo/:fileName',
  'UPLOAD_REPORT_IMAGE' = '/groups/:groupID/report/:reportID/icon/:fileName',

  'GET_GROUP_REPORTS' = '/groups/:groupID/publishedReports',
  'GET_GROUP_REPORTS_DETAILS' = '/groups/:groupID/Reports',

  'GET_USERS' = '/users',
  'GET_USER' = '/users/:userID',
  'PUT_OWNER' = '/groups/:securityGroupID/users/owner',
  'PUT_USER' = '/groups/:securityGroupID/users',//Invite User
  'POST_USER_BY_OWNER' = '/groups/:securityGroupID/users/owner',//Add User by Owner

  'GIVE_USER_ACCESS' = '/users/:securityGroupID',//Select User - put request
  'POST_USER' = '/groups/:securityGroupID/users',
  'REVOKE_USER_ACCESS' = '/groups/:groupID/RevokeAccessUser/:userID',
  'REVOKE_OWNER_ACCESS' = '/groups/:groupID/RevokeOwnerAccessUser/:userID',

  'DELETE_USER' = '/user/:userID',
  'GET_API_REPORT' = '/powerbi/:groupID/:reportType/:reportID',
  'GET_REPORT' = '/groups/:groupID/report/:reportID',
  'GET_PINNED_FAVORITES_REPORTS' = '/reports/pinned-favorites',
  'PUT_REPORT' = '/groups/:groupID/report/:reportID',
  'PUT_REPORT_FAVORITE' = '/report/:reportID/favorite',

  'GET_ADMIN_HELP_PAGES' = '/config/help-pages',
  'GET_HELP_PAGE' = '/help-pages/:helpPageID',
  'GET_HELP_PAGES' = '/help-pages',
  'PUT_HELP_PAGE' = '/config/help-pages/:helpPageID',
  'POST_HELP_PAGE' = '/config/help-pages',
  'DELETE_HELP_PAGE' = '/config/help-pages/:helpPageID',
  'UPLOAD_IMAGE' = '/blobUploader/help-pages-images/:fileName',

  'UPLOAD_LOGO' = '/blobUploader/ConfigurationImage/:fileName',
  'GET_API_VERSION' = '/configuration/apiVersion'
}
